import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

// eslint-disable-next-line no-console
console.log("Index.tsx")

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
)
