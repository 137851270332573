import { fetchUtils } from "react-admin"

import simpleRestProvider from "ra-data-simple-rest"

const env = () => {
	let apiUrl = process.env.REACT_APP_API_URL

	if (process.env.NODE_ENV === "production") {
		// eslint-disable-next-line
		apiUrl = (window as any).REACT_APP_API_URL
	}

	return {
		apiUrl
	}
}

export const getUrl = (route: string): string => {
	return `${env().apiUrl}${route}`
}

const httpClient = (url: string, options: any = {}) => {
	options.headers = new Headers({ Accept: "application/json" })

	const token = localStorage.getItem("token")

	if (token) {
		options.headers.set("Authorization", `Bearer ${token}`)
	}

	return fetchUtils.fetchJson(url, options)
}

const apiUrl = getUrl("")

export const dataProvider = simpleRestProvider(apiUrl, httpClient)
