import React, { useEffect, useState } from "react"
import {
	Edit,
	SimpleForm,
	TextInput,
	BooleanInput,
	useRecordContext,
	ImageInput,
	ImageField,
	Loading
} from "react-admin"
import IPackErrors from "utils/types/i-pack-error"

export interface FileField {
	name: string
	label: string
	preview: string
	placeholder: string
	accept: string
}

const PackEdit = () => {
	const colorRegex = /^#?(?<color>[a-f0-9]{6})$/u

	const validateForm = (values: any) => {
		const errors: IPackErrors = {}

		if (!values.defaultZone1 || !colorRegex.test(values.defaultZone1)) {
			errors.defaultZone1
				= "Veuillez renseigner une couleur hexadécimale correcte pour la zone 1"
		}

		if (!values.defaultZone2 || !colorRegex.test(values.defaultZone2)) {
			errors.defaultZone2
				= "Veuillez renseigner une couleur hexadécimale correcte pour la zone 2"
		}

		if (!values.previewFile && !values.previewUrl) {
			errors.previewFile = "Une image est de preview est nécessaire"
		}

		if (!values.squareFormatFile && !values.squareFormatUrl) {
			errors.squareFormatFile
				= "Un fichier au format svg est nécessaire dans au format carré"
		}

		if (!values.storyFormatFile && !values.storyFormatUrl) {
			errors.storyFormatFile
				= "Un fichier au format svg est nécessaire dans au format story"
		}

		return errors
	}

	const Form = () => {
		const record = useRecordContext()
		// eslint-disable-next-line no-array-constructor
		const [fileFields, setFileFields] = useState(new Array<FileField>())

		useEffect(() => {
			if (record !== null) {
				setFileFields([
					{
						name: "previewFile",
						label: "Preview du pack",
						preview: record.previewUrl,
						placeholder: "Insérer une nouvelle preview",
						accept: "image/png,image/jpeg,image/jpg"
					},
					{
						name: "squareFormatFile",
						label: "Format carré",
						preview: record.squareFormatUrl,
						placeholder: "Insérer un nouveau format carré",
						accept: "image/xml,image/svg+xml"
					},
					{
						name: "storyFormatFile",
						label: "Format story",
						preview: record.storyFormatUrl,
						placeholder: "Insérer un nouveau format story",
						accept: "image/xml,image/svg+xml"
					}
				])
			}
		}, [record])

		if (!record) {
			return <Loading />
		}

		return (
			<div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<TextInput source="name" label="Nom du pack" required />
					<TextInput
						source="description"
						label="Description du pack"
						sx={{
							width: 500,
							maxWidth: 500
						}}
						required
					/>
					<TextInput
						source="defaultZone1"
						label="Couleur par défaut zone 1"
						sx={{
							width: 500,
							maxWidth: 500
						}}
						required
					/>
					<TextInput
						source="defaultZone2"
						label="Couleur par défaut zone 2"
						sx={{
							width: 500,
							maxWidth: 500
						}}
						required
					/>
					<BooleanInput
						source="isPremium"
						label="Pack réservé aux premiums ?"
					/>
				</div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					{fileFields.map((input, index) => <div key={index}>
						<ImageInput
							sx={{
								marginTop: 5,
								marginBottom: 10,
								width: 500,
								maxWidth: 500
							}}
							placeholder={input.placeholder}
							label={input.label}
							source={input.name}
							accept={input.accept}
							isRequired>
							<ImageField source="src" title="title" />
						</ImageInput>
						{input.preview && <>
							<p style={{ fontFamily: "Helvetica" }}>Version actuelle :</p>
							<img src={input.preview} style={{ maxHeight: 100 }} />
						</>}
					</div>)}
				</div>
			</div>
		)
	}

	return (
		<Edit>
			<SimpleForm validate={validateForm}>
				<Form />
			</SimpleForm>
		</Edit>
	)
}

export default PackEdit
