import React from "react"
import { Admin, Resource } from "react-admin"

import NotFound from "utils/NotFound"

import { ThemeProvider, createTheme } from "@mui/material/styles"

import UserList from "components/users/userList"
import UserEdit from "components/users/userEdit"
import UserCreate from "components/users/userCreate"

import UserIcon from "@mui/icons-material/Group"
import FilterIcon from "@mui/icons-material/Filter"

import { dataProvider } from "./utils/api"
import customDataProvider from "utils/customDataProvider"
import authProvider from "authProvider"
import PackList from "components/packs/packList"
import PackEdit from "components/packs/packEdit"
import PackCreate from "components/packs/packCreate"

// eslint-disable-next-line no-console
console.log("App.tsx")
const theme = createTheme()

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Admin
				catchAll={NotFound}
				dataProvider={customDataProvider}
				authProvider={authProvider}>
				<Resource
					icon={UserIcon}
					name="users"
					list={UserList}
					edit={UserEdit}
					create={UserCreate}
				/>
				<Resource
					icon={FilterIcon}
					name="packs"
					list={PackList}
					edit={PackEdit}
					create={PackCreate}
				/>
			</Admin>
		</ThemeProvider>
	)
}

export default App
