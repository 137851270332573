import { fetchUtils } from "react-admin"

import simpleRestProvider from "ra-data-simple-rest"

const env = () => {
	let apiUrl = process.env.REACT_APP_API_URL

	if (process.env.NODE_ENV === "production") {
		// eslint-disable-next-line
		apiUrl = (window).REACT_APP_API_URL
	}

	return {
		apiUrl
	}
}

export const getUrl = (route) => {
	return `${env().apiUrl}${route}`
}

const httpClient = (url, options = {}) => {
	options.headers = new Headers({ Accept: "application/json" })

	const token = localStorage.getItem("token")

	if (token) {
		options.headers.set("Authorization", `Bearer ${token}`)
	}

	return fetchUtils.fetchJson(url, options)
}

const apiUrl = getUrl("")

const dataProvider = simpleRestProvider(apiUrl, httpClient)


/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
	const reader = new FileReader()

	reader.readAsDataURL(file.rawFile)
	reader.onload = () => resolve(reader.result)
	reader.onerror = reject
})


const handleAttachment = async (attachment) => {

	if (!attachment) {
		return [null, null]
	}

	if (!(attachment.rawFile instanceof File)) {
		return [null, null]
	}

	const base64File = await convertFileToBase64(attachment)

	return [
		base64File,
		attachment.rawFile.type
	]
}

const customDataProvider = {
	...dataProvider,
	create: async (resource, params) => {
		if (resource !== "packs") {
			// Fallback to the default implementation
			return dataProvider.create(resource, params)
		}

		const [previewBase64, previewContentType] = await handleAttachment(params.data.previewFile)
		const [squareFormatBase64, squareFormatContentType] = await handleAttachment(params.data.squareFormatFile)
		const [storyFormatBase64, storyFormatContentType] = await handleAttachment(params.data.storyFormatFile)

		return dataProvider.create(resource, {
			id: params.data.id,
			data: {
				...params.data,
				previewBase64,
				previewContentType,
				squareFormatBase64,
				squareFormatContentType,
				storyFormatBase64,
				storyFormatContentType
			}
		})
	},
	update: async (resource, params) => {
		if (resource !== "packs") {
			// Fallback to the default implementation
			return dataProvider.update(resource, params)
		}

		const [previewBase64, previewContentType] = await handleAttachment(params.data.previewFile)
		const [squareFormatBase64, squareFormatContentType] = await handleAttachment(params.data.squareFormatFile)
		const [storyFormatBase64, storyFormatContentType] = await handleAttachment(params.data.storyFormatFile)

		return dataProvider.update(resource, {
			id: params.data.id,
			data: {
				...params.data,
				previewBase64,
				previewContentType,
				squareFormatBase64,
				squareFormatContentType,
				storyFormatBase64,
				storyFormatContentType
			}
		})
	}
}

export default customDataProvider
