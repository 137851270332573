import React from "react"
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	BooleanField,
	DateField
} from "react-admin"

const UserList = () => {
	return (
		<List>
			<Datagrid rowClick="edit">
				<TextField source="id" />
				<TextField source="name" label="Nom" />
				<TextField source="location" label="Localisation" />
				<TextField source="sport" label="Sport" />
				<TextField source="league" label="Ligue" />
				<EmailField source="email" label="Email" />
				<BooleanField source="isPremium" label="Premium" textAlign="center" />
				<BooleanField
					source="subscriptionValid"
					label="Abonnement valide"
					textAlign="center"
					sortable={false}
				/>
				<BooleanField source="active" label="Activé" textAlign="center" />
				<TextField
					source="teamCount"
					label="Nombre d'équipes"
					textAlign="center"
					sortable={false}
				/>
				<DateField source="createdAt" locales="fr-FR" label="Date de création"/>
				<DateField source="updatedAt" locales="fr-FR" label="Date de mise à jour" />
			</Datagrid>
		</List>
	)
}

export default UserList
