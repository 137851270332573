import React from "react"
import {
	Edit,
	SimpleForm,
	TextInput,
	BooleanInput,
	useRecordContext,
	Loading
} from "react-admin"

import dayjs from "dayjs"

const UserEdit = () => {
	const displayTeam = (team: any) => (
		<ul>
			<li>
				{team.name} - Nombre de joueurs : {team.players.length}
			</li>
		</ul>
	)

	const displaySubscription = (subscription: any) => (
		<div>
			<p>
				Plateforme: {subscription.type === "ios" ? "App Store" : "Play Store"}
			</p>
			{subscription.type === "ios"
				? (
					<p>Dernier status: {subscription.appStoreLastStatus}</p>
				)
				: (
					<div>
						<p>Produit: {subscription.playStoreProductId}</p>
						<p>Achat: {subscription.playStorePurchaseToken}</p>
					</div>
				)}

			<p>Expire le {dayjs(subscription.endAt).format("DD/MM/YYYY HH:MM")}</p>
		</div>
	)

	const Form = () => {
		const record = useRecordContext()

		if (!record) {
			return <Loading />
		}

		return (
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					width: "100%"
				}}>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<TextInput
						source="name"
						label="Nom de l'équipe"
						sx={{
							width: 500,
							maxWidth: 500
						}}
					/>
					<TextInput
						source="location"
						label="Localisation"
						sx={{
							width: 500,
							maxWidth: 500
						}}
					/>
					<TextInput
						source="email"
						label="Email"
						sx={{
							width: 500,
							maxWidth: 500
						}}
					/>
					<TextInput source="sport" label="Sport" />
					<TextInput source="league" label="Ligue" />
					<BooleanInput source="isPremium" label="Utilisateur premium ?" />
					<BooleanInput source="active" label="Utiliseur activé ?" />

					<div style={{ fontFamily: "Helvetica" }}>
						<h2>Equipe(s)</h2>
						<>
							{record.teams && record.teams.length > 0
								? (
									record.teams.map((team: any) => displayTeam(team))
								)
								: (
									<p>Pas d'équipe</p>
								)}
						</>
					</div>
					<div style={{ fontFamily: "Helvetica" }}>
						<h2>Abonnement</h2>
						<>
							{record.subscription
								? (
									displaySubscription(record.subscription)
								)
								: (
									<p>Pas d'abonnement</p>
								)}
						</>
					</div>
				</div>
				<div>
					{record.logos ? <img src={record.logos[0].logoUrl} /> : <></>}
				</div>
			</div>
		)
	}

	return (
		<Edit>
			<SimpleForm>
				<Form></Form>
			</SimpleForm>
		</Edit>
	)
}

export default UserEdit
