import React from "react"
import {
	Create,
	SimpleForm,
	TextInput,
	BooleanInput,
	ImageInput,
	ImageField,
	useNotify
} from "react-admin"
import IPackErrors from "utils/types/i-pack-error"

const PackCreate = () => {
	const colorRegex = /^#?(?<color>[a-f0-9]{6})$/u
	const notify = useNotify()

	const inputFile = [
		{
			name: "previewFile",
			label: "Preview du pack",
			placeholder: "Insérer la preview",
			accept: "image/png,image/jpeg,image/jpg"
		},
		{
			name: "squareFormatFile",
			label: "Format carré",
			placeholder: "Insérer le format carré",
			accept: "image/xml,image/svg+xml"
		},
		{
			name: "storyFormatFile",
			label: "Format story",
			placeholder: "Insérer le format story",
			accept: "image/xml,image/svg+xml"
		}
	]

	const validateForm = (values: any) => {
		const errors: IPackErrors = {}

		if (!values.defaultZone1 || !colorRegex.test(values.defaultZone1)) {
			errors.defaultZone1
				= "Veuillez renseigner une couleur hexadécimale correcte pour la zone 1"
		}

		if (!values.defaultZone2 || !colorRegex.test(values.defaultZone2)) {
			errors.defaultZone2
				= "Veuillez renseigner une couleur hexadécimale correcte pour la zone 2"
		}

		if (!values.previewFile) {
			errors.previewFile = "Une image est de preview est nécessaire"
		}

		if (!values.squareFormatFile) {
			errors.squareFormatFile
				= "Un fichier au format svg est nécessaire dans au format carré"
		}

		if (!values.storyFormatFile) {
			errors.storyFormatFile
				= "Un fichier au format svg est nécéssaire dans au format story"
		}

		if (Object.keys(errors).length > 0) {
			notify("Formulaire incorrect", { type: "error" })
		}

		return errors
	}

	return (
		<Create redirect="list">
			<SimpleForm validate={validateForm}>
				<div>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<TextInput
							source="name"
							label="Nom du pack"
							required
							sx={{
								width: 500,
								maxWidth: 500
							}}
						/>
						<TextInput
							source="description"
							label="Description du pack"
							required
							sx={{
								width: 500,
								maxWidth: 500
							}}
						/>
						<TextInput
							source="defaultZone1"
							label="Couleur par défaut zone 1"
							required
							sx={{
								width: 500,
								maxWidth: 500
							}}
						/>
						<TextInput
							source="defaultZone2"
							label="Couleur par défaut zone 2"
							required
							sx={{
								width: 500,
								maxWidth: 500
							}}
						/>
						<BooleanInput
							source="isPremium"
							label="Pack réservé aux premiums ?"
						/>
					</div>
					<div style={{ display: "flex", flexDirection: "column" }}>
						{inputFile.map((input, index) => <ImageInput
							key={index}
							sx={{
								marginTop: 5,
								marginBottom: 10,
								width: 500,
								maxWidth: 500
							}}
							placeholder={input.placeholder}
							label={input.label}
							source={input.name}
							accept={input.accept}
							isRequired>
							<ImageField source="src" title="title" />
						</ImageInput>)}
					</div>
				</div>
			</SimpleForm>
		</Create>
	)
}

export default PackCreate
