import { getUrl } from "utils/api"

interface ICredentials {
	username: string
	password: string
}

const authProvider = {
	login: (credentials: ICredentials) => {
		const { username, password } = credentials

		const request = new Request(getUrl("/admin/login"), {
			method: "POST",
			body: JSON.stringify({ username, password }),
			headers: new Headers({ "Content-Type": "application/json" })
		})

		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText)
				}

				return response.json()
			})
			.then(({ token }) => localStorage.setItem("token", token))
	},

	logout: () => {
		localStorage.removeItem("token")

		return Promise.resolve()
	},

	checkAuth: () => {
		return localStorage.getItem("token") === null
			? Promise.reject(new Error())
			: Promise.resolve()
	},

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	checkError: (error: any) => {
		const { status } = error

		if (status === 401 || status === 403) {
			localStorage.removeItem("token")

			return Promise.reject(new Error())
		}

		return Promise.resolve()
	},

	getPermissions: () => {
		return localStorage.getItem("token") === null
			? Promise.reject(new Error())
			: Promise.resolve()
	}
}

export default authProvider
