import React from "react"
import {
	List,
	Datagrid,
	TextField,
	BooleanField,
	ImageField,
	DateField
} from "react-admin"

const PackList = () => {
	return (
		<List>
			<Datagrid rowClick="edit">
				<TextField source="name" label="Nom du pack" />
				<ImageField
					source="previewUrl"
					label="Preview"
					sx={{
						width: "100%",
						height: "150px",
						objectFit: "contain"
					}}
				/>
				<TextField source="defaultZone1" label="Couleur Zone 1" />
				<TextField source="defaultZone2" label="Couleur Zone 2" />
				<BooleanField
					source="isPremium"
					label="Réservé au premium ?"
					textAlign="center"
				/>
				<DateField source="createdAt" locales="fr-FR" label="Date de création"/>
				<DateField source="updatedAt" locales="fr-FR" label="Date de mise à jour" />
			</Datagrid>
		</List>
	)
}

export default PackList
