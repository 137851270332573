import React from "react"
import {
	Create,
	SimpleForm,
	TextInput,
	PasswordInput,
	BooleanInput
} from "react-admin"

const UserCreate = (props: any) => {
	return (
		<Create {...props} redirect="list">
			<SimpleForm>
				<TextInput
					source="name"
					label="Nom de l'équipe"
					sx={{
						width: 500,
						maxWidth: 500
					}}
				/>
				<TextInput
					source="location"
					label="Localisation"
					sx={{
						width: 500,
						maxWidth: 500
					}}
				/>
				<TextInput
					source="email"
					label="Email"
					sx={{
						width: 500,
						maxWidth: 500
					}}
				/>
				<PasswordInput source="password" label="Mot de passe" />
				<TextInput source="sport" label="Sport" />
				<TextInput source="league" label="Ligue" />
				<BooleanInput source="isPremium" label="Utilisateur premium ?" />
				<BooleanInput source="active" label="Utiliseur activé ?" />
			</SimpleForm>
		</Create>
	)
}

export default UserCreate
